import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  async fetch(event) {
    event.preventDefault();
    event.stopPropagation();

    try {
      const response = await fetch(event.target.dataset.url);

      this.containerTarget.innerHTML = await response.text();
    } catch(e) {
      console.error(e);
    }
  }
}
